<template>
  <div>
    <v-row>
      <v-col>
        <v-alert class="mt-5" color="grey lighten-3" style="width: 100%">
          <div
            class="d-flex flex-column align-center justify-md-space-between flex-md-row"
          >
            <div>
              Pensando em ficar por um tempo?
              <br />
              <b>Assine o plano anual e ganhe 20% de desconto!</b>
            </div>
            <!-- <div class="mt-5 mt-md-0">
              <v-chip-group mandatory active-class="primary--text">
                <v-chip>
                  Assinatura Mensal
                </v-chip>
                <v-chip>
                 Assinatura Anual
                </v-chip>
              </v-chip-group>
            </div> -->
          </div>
        </v-alert>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <template v-for="(plan, index) in plans">
        <v-col cols="12" md="4" :key="index">
          <v-card outlined>
            <v-card-title class="d-flex justify-center">
              <h2 class="font-weight-medium text--secondary">
                {{ plan.name }}
              </h2>
            </v-card-title>
            <v-card-subtitle>
              <v-row class="mt-2">
                <v-col>
                  <div class="d-flex justify-center">
                    R$
                    <h1 class="ml-1 primary--text font-weight-medium">
                      {{ $format.decimal(plan.value) }}
                    </h1>
                    /Mês
                  </div>
                </v-col>
              </v-row>
            </v-card-subtitle>
            <v-card-text class="my-4 text-center">
              <!-- <span v-html="plan.description" /> -->
              <template v-if="plan.type == 'clinic'">
                <span>{{ getProfessionalLimitString(plan) }}</span>
                <br />
                <span>{{ getAttendantLimitString(plan) }}</span>
              </template>
              <template v-else> Para uso individual </template>
            </v-card-text>
            <v-card-actions class="default-card">
              <v-btn @click="selectPlan(plan)" block color="primary">
                Assinar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      plans: [],
    };
  },
  mounted() {
    this.select();
  },

  computed: {
    company() {
      return this.$store.state.auth.company;
    },

    companyType() {
      return this.$store.state.auth.company.type;
    },

    params() {
      return !this.$store.state.auth.user.is_admin
        ? { status: "active" }
        : null;
    },
  },

  methods: {
    selectPlan(plan) {
      this.$emit("input", plan);
    },

    select() {
      this.$http.index("subscription/plans", this.params).then((response) => {
        this.setPlans(response.plans, this.companyType);
      });
    },

    setPlans(plans, type) {
      this.plans = plans.filter((plan) => {
        return plan.type === type && plan.vendor == "pagarme";
      });
    },

    getProfessionalLimitString(plan) {
      if (plan.professional_limit == 1) {
        return "Para 1 profissional";
      }

      if (plan.professional_limit > 1) {
        return "Para até " + plan.professional_limit + " profissionais";
      }
    },

    getAttendantLimitString(plan) {
      if (plan.attendant_limit > 0) {
        return "Cadastro de atendentes";
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.default-card
  background-color: white
</style>
