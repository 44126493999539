<template>
  <div>
    <v-row class="mt-4">
      <v-col cols="12" md="6">
        <!-- <vue-paycard
            :hasRandomBackgrounds="false"
            :value-fields="valueFields"
          /> -->
        <h4>Informações do cartão</h4>
        <v-row class="mt-4">
          <v-col cols="12">
            <app-mask-field
              v-model="form.card_number"
              label="número do cartão"
              mask="#### #### #### ####"
            />
          </v-col>
          <v-col cols="12">
            <app-text-field
              v-model="form.card_holder_name"
              label="Nome do titular"
            />
          </v-col>

          <v-col cols="12" md="6">
            <app-text-field v-model="form.card_cvv" label="CVV" />
          </v-col>
          <v-col cols="12" md="6">
            <app-mask-field
              placeholder="MM/AA"
              v-model="form.card_expiration_date"
              label="Vencimento"
              mask="##/##"
            />
          </v-col>
          <v-col class="mt-5">
            <v-btn @click="handleStore()" block color="primary">Assinar</v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <v-card color="grey lighten-3 " elevation="0">
          <v-card-title>Resumo</v-card-title>
          <v-card-text>
            <v-row>
              <v-col> {{ plan.name }} </v-col>
              <v-col class="text-right">
                <h2>R$ {{ $format.decimal(plan.value) }}</h2>
                <template v-if="plan.days == 30">
                  <small>Pagamento mensal</small>
                </template>
                <template v-if="plan.days == 365">
                  <small>Pagamento anual</small>
                </template>
              </v-col>
            </v-row>
            <v-divider class="mt-5 mb-5" />
            <v-row>
              <v-col>
                <small>Email:</small>
                <br />
                {{ billingInformation.email }}
                <br />
                <small>Nome:</small>
                <br />
                {{ billingInformation.name }}
                <br />
                <small>Cpf:</small>
                <br />
                {{ billingInformation.cpf }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import { VuePaycard } from "vue-paycard";

export default {
  props: {
    plan: {},
    billingInformation: {},
  },

  data: () => ({
    form: {
      plan_id: null,
      card_number: null,
      card_holder_name: null,
      card_cvv: null,
      card_expiration_date: null,
    },
  }),

  methods: {
    handleStore() {
      this.form.plan_id = this.plan.id;

      this.$loading.start();

      this.$http
        .store("subscription/subscription", this.form)
        .then((response) => {
          this.$auth
            .getLoginData()
            .then((response) => {
              this.$loading.finish();
              this.processSaved();
            })
            .catch((response) => {
              this.$loading.finish();
            });
        })
        .catch((response) => {
          this.$loading.finish();
        });
    },

    processSaved() {
      this.$tracker.track("Subscribe");

      this.$emit("store");
    },
  },
};
</script>

<style>
</style>