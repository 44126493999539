<template>
  <div>
    <div v-show="step == 1">
      <v-row class="mb-2" v-if="showAlert">
        <v-col>
          <v-alert text type="primary">
            Seu período de teste terminou. Assine já e economize tempo com o
            Psicoplanner!</v-alert
          >
        </v-col>
      </v-row>
      <h2>1 - Selecione o seu plano</h2>
      <PlanSelection @input="handlePlanSelection($event)" />
    </div>
    <div v-show="step == 2">
      <h2>2 - Informe os dados de cobrança</h2>
      <BaseBillingInformationForm
        class="mt-10"
        @back="step--"
        @store="handleStoreBillingInformation($event)"
      />
    </div>
    <div v-show="step == 3">
      <h2>3 - Pagamento</h2>
      <SubscriptionPaymentForm
        :plan="form.plan"
        :billingInformation="form.billingInformation"
        @store="step++"
      />
    </div>
    <div class="text-center" v-show="step == 4">
      <h2>Parabéns</h2>
      <br />
      <span class="mt-10">Sua assinatura do Psicoplanner foi efetivada!</span>
      <br />
      <v-btn class="mt-8" color="primary" @click="$router.push('/')"
        >Prosseguir</v-btn
      >
    </div>
  </div>
</template>

<script>
import PlanSelection from "@/components/subscription/sections/PlanSelection.vue";
import BaseBillingInformationForm from "@/components/subscription/forms/BaseBillingInformationForm.vue";
import SubscriptionPaymentForm from "@/components/subscription/forms/SubscriptionPaymentForm.vue";
import { isAfter, parseISO } from "date-fns";

export default {
  components: {
    PlanSelection,
    BaseBillingInformationForm,
    SubscriptionPaymentForm,
  },

  data() {
    return {
      step: 1,
      form: {
        plan: {},
        billingInformation: {},
        card_cvv: null,
        card_number: null,
        card_holder_name: null,
        card_expiration_date: null,
      },
    };
  },

  created() {
    // let signupDate = parseISO(this.company.created_at)
    // if (isAfter(signupDate, parseISO('2022-09-30'))) {
    //   this.$router.push('/assinatura/checkout')
    // }
  },

  mounted() {},

  computed: {
    user() {
      return this.$store.state.auth.user;
    },

    company() {
      return this.$store.state.auth.company;
    },

    isOnTrial(state, getters) {
      if (getters.hasSubscription) {
        return false;
      }

      return true;
    },

    isOnTrial() {
      return this.$store.getters["subscription/isOnTrial"];
    },

    isTrialEnded() {
      return this.$store.getters["subscription/isTrialEnded"];
    },

    showAlert() {
      return this.isOnTrial && this.isTrialEnded;
    },
  },

  methods: {
    handlePlanSelection(plan) {
      this.form.plan = plan;
      this.step++;
    },

    handleStoreBillingInformation(billingInformation) {
      this.form.billingInformation = billingInformation;
      this.step++;
    },
  },
};
</script>

<style>
</style>